const MENU_ITEM_MY_ACCOUNT = 'my_account';
const MENU_ITEM_WAREHOUSE = 'warehouse';
const MENU_ITEM_MY_SHIPMENTS = 'my_shipments';
const MENU_ITEM_USER_INFORMATION = 'user_information';
const MENU_ITEM_HELP = 'help';
const MENU_ITEM_PACKAGE_RETURN = 'package_return';


export {
    MENU_ITEM_MY_ACCOUNT,
    MENU_ITEM_WAREHOUSE,
    MENU_ITEM_MY_SHIPMENTS,
    MENU_ITEM_USER_INFORMATION,
    MENU_ITEM_PACKAGE_RETURN,
    MENU_ITEM_HELP,
};
