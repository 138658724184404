import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {

  return (
    <Box sx={{ width: 160, height: 50, ...sx }} component="img" alt="logo" src={'/static/icons/logo.svg'} />
  );
}
