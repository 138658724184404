import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import mockedAxios from '../../utils/mockedAxios';
import axios from '../../utils/axios';
import userManager from "../../services/userManager";
import {
  FEE_TYPE_DELIVER_TO_DOOR,
  FEE_TYPE_INVOICE_REMOVAL,
  FEE_TYPE_LABEL_REMOVAL,
} from "../../constants";

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  fees: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    hash: null,
    activeStep: 0,
    cart: [],
    additionalFees: 0,
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    vat_price: 0,
    merge_discount: 0,
    shipping_price: 0,
    billing: null,
    selected_shipping: null,
    additional_information: '',
    userSelectedFees: [],
    loyalty_points_discount: null,
    loyalty_points_used: null,
  }
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET PRODUCT
    getFeesSuccess(state, action) {
      state.isLoading = false;
      state.fees = action.payload;
    },

    setFeesAddedSuccessfully(state, action) {
      state.isLoading = false;
      const cartFees = action.payload.cart_fees;

      state.checkout.userSelectedFees = cartFees.map(item => item.fee.type);
    },

    setFeesRemovedSuccessfully(state, action) {
      state.isLoading = false;

      let cartFees = action.payload.cart_fees;

      if (!Array.isArray(cartFees)) {
          cartFees = Object.values(cartFees);
      }

      state.checkout.userSelectedFees = cartFees.map(item => item.fee.type);
    },

    setAvailableShippingCities(state, action) {
      state.availableParcelCities = action.payload.cities;
    },

    setAvailableParcels(state, action) {
      state.availableParcels = action.payload.items;
    },

    setCartSuccess(state, action) {
      const {
        hash,
        cart_orders,
        total_price,
        vat_price,
        discount_price,
        fee_price,
        cart_shipping,
        merge_discount,
        merge_price,
        shipping_price,
        loyalty_points_discount,
        loyalty_points_used,
      } = action.payload;

      const orders = cart_orders.map(item => item.order);

      state.isLoading = false;
      state.checkout.hash = hash;
      state.checkout.cart = orders;
      state.checkout.total = total_price;
      state.checkout.vat_price = vat_price;
      state.checkout.discount = discount_price;
      state.checkout.fee_price = fee_price;
      state.checkout.merge_discount = merge_discount;
      state.checkout.merge_price = merge_price;
      state.checkout.selected_shipping = cart_shipping;
      state.checkout.shipping_price = shipping_price;
      state.checkout.loyalty_points_discount = loyalty_points_discount;
      state.checkout.loyalty_points_used = loyalty_points_used;
    },

    setOrderAppliedSuccessfully(state, action) {
      console.log('setOrderAppliedSuccessfully', action.payload);
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, { id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const checkout = action.payload;
      const { cart } = checkout;

      const subtotal = sum(cart.map((product) => product.amount));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.hash = null;

      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.additionalFees = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
      state.checkout.fees = [];
      state.checkout.feesPrice = 0;
      state.checkout.merge_discount = 0;
      state.checkout.merge_price = 0;
      state.checkout.shipping_price = 0;
      state.checkout.userSelectedFees = [];
      state.checkout.additional_information = '';
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    changeAdditionalText(state, action) {
      const additionalInformationText = action.payload;

      state.checkout.additional_information = additionalInformationText;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity,
  setCartSuccess,
  changeAdditionalText,
  setAvailableShippingCities,
} = slice.actions;

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/orders/rest/v1/users/${userManager.getUser().token}/orders`);

      dispatch(slice.actions.getProductsSuccess(response.data.items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProduct(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await mockedAxios.get('/api/products/product', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFees() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/fees/rest/v1/fees',
      {
          params: {
            types: [
                FEE_TYPE_LABEL_REMOVAL,
                FEE_TYPE_INVOICE_REMOVAL,
                FEE_TYPE_DELIVER_TO_DOOR,
            ],
        },
      });

      dispatch(slice.actions.getFeesSuccess(response.data.items));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addFeeToCart(hash, fee) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${hash}/fees`,
          {
            fee_hash: fee.hash,
            count: fee.count
          }
      );

      dispatch(slice.actions.setFeesAddedSuccessfully(response.data));
      dispatch(slice.actions.setCartSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeFeeFromCart(hash, feeHash) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/carts/rest/v1/carts/${hash}/fees/${feeHash}`);

      dispatch(slice.actions.setFeesRemovedSuccessfully(response.data));
      dispatch(slice.actions.setCartSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function applyDiscountForCart(cartHash, code, enqueueSnackbar) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${cartHash}/discount`,
          {
            discount_code: code,
          },
      );

      dispatch(slice.actions.setCartSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.error_description, { variant: 'error' });
    }
  };
}

export function applyLoyaltyPoints(cartHash, points, enqueueSnackbar) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${cartHash}/loyalty-points`,
          {
            points: points.toString(),
          },
      );

      dispatch(slice.actions.setCartSuccess(response.data));
      enqueueSnackbar('Monetos sekmingai panaudotos', { variant: 'success' });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.error_description, { variant: 'error' });
    }
  };
}

export function addUpsellToCart(cartHash, productHash, enqueueSnackbar, onSuccess) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${cartHash}/upsell`,
          {
            product_hash: productHash,
            count: 1,
          },
      );

      dispatch(slice.actions.setCartSuccess(response.data));
      onSuccess();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.error_description, { variant: 'error' });
    }
  };
}

export function removeUpsellFromCart(cartHash, productHash, enqueueSnackbar, onSuccess) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/carts/rest/v1/carts/${cartHash}/upsell/${productHash}`);

      dispatch(slice.actions.setCartSuccess(response.data));
      onSuccess();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      enqueueSnackbar(error.error_description, { variant: 'error' });
    }
  };
}

export function addAdditionalTextToCart(cartHash, text) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${cartHash}/additional-information`,
          {
            additional_information: text,
          }
      );

      dispatch(slice.actions.setCartSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addShippingToCart(cartHash, body, onFail, onSuccess) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/${cartHash}/shipping`,
          body
      );

      dispatch(slice.actions.setCartSuccess(response.data));
      onSuccess();
    } catch (error) {
      console.error(error);
      onFail();
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateShippingToCart(shippingHash, body, onFail, onSuccess) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
          `/carts/rest/v1/carts/shipping/${shippingHash}`,
          body
      );

      dispatch(slice.actions.setCartSuccess(response.data));
      onSuccess();
    } catch (error) {
      console.error(error);
      onFail();
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeShipping(shippingHash) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/carts/rest/v1/carts/shipping/${shippingHash}`);

      dispatch(slice.actions.setCartSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAvailableCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
          `/shipping/rest/v1/available-cities`,
          {
            params: {
              country: 'LT',
            },
          }
      );

      dispatch(slice.actions.setAvailableShippingCities(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParcels() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
          `/shipping/rest/v1/parcels`,
          {
            params: {
              country: 'LT',
              city: 'Vilnius',
            },
          }
      );

      dispatch(slice.actions.setAvailableParcels(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
