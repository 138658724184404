import axios from 'axios';
import { userApiHost } from '../config';

const axiosInstance = axios.create({
    'baseURL': userApiHost,
    headers: {
        'X-System': 'atvyko'
    },
});

axiosInstance.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
