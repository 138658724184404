import * as Sentry from "@sentry/react";
import Router from './routes';
import ThemeConfig from './theme';
import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import Referral from './components/Referral';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Fingerprint from "./components/Fingerprint";
// import FacebookPixel from "./components/FacebookPixel";

Sentry.init({
  dsn: "https://0e2ca7ddda9a484c8c969eb33e63af33@sentry.atvyko.lt/2",
  tracesSampleRate: 1.0,
  allowUrls: 'app.atvyko.lt',
});

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Referral />
              <ScrollToTop />
              <GoogleAnalytics />
              <Fingerprint />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
