import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';
import {LOCAL_STORAGE_VIEWED_HELP_PAGE} from "../constants";

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (!user.approved && localStorage.getItem(LOCAL_STORAGE_VIEWED_HELP_PAGE) === null) {
      return <Navigate to={PATH_DASHBOARD.help.root} />;
    }

    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}
