import {createContext, useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import userApiAxios from '../utils/userApiAxios';
import userManager from '../services/userManager';
import ReactPixel from 'react-facebook-pixel';
import {REFERRAL_CODE} from "../constants";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userInState, setUser] = useState(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`/users/rest/v1/users/${userManager.getUser().token}/info`);

          let user = {...response.data, photoURL: "https://atvyko.s3.eu-central-1.amazonaws.com/person.png"};

          try {
            const loyaltyPointsResponse = await axios.get(
                `/loyalty/rest/v1/points/user/${userManager.getUser().token}`
            );

            user = {...user, loyalty_points: loyaltyPointsResponse.data};
          } catch (error) {
            user = {...user, loyalty_points: { points: 0, hash: null }};
          }

          setUser(user);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loadUser = async () => {
    const response = await axios.get(`/users/rest/v1/users/${userManager.getUser().token}/info`);

    let user = {...response.data, photoURL: "https://atvyko.s3.eu-central-1.amazonaws.com/person.png"};

    try {
      const loyaltyPointsResponse = await axios.get(
          `/loyalty/rest/v1/points/user/${userManager.getUser().token}`
      );

      user = {...user, loyalty_points: loyaltyPointsResponse.data};
    } catch (error) {
      user = {...user, loyalty_points: { points: 0, hash: null }};
    }

    setUser(user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const loginWithToken = async (token) => {
    setSession(token);
    await loadUser();
  };

  const login = async (email, password) => {
    const token = await getLoginToken(email, password);

    gtag('event', 'login', {});
    setSession(token);
    await loadUser();
  };

  const getLoginToken = async (email, password) => {
    const response = await userApiAxios.post('/authentication/rest/v1/token', {
      username: email,
      password
    });

    const { token } = response.data;

    return token;
  };

  const register = async (email, password, firstName, lastName, sendMarketingEmails) => {
    let data = {
      email,
      password,
      name: firstName,
      surname: lastName,
      send_marketing_emails: sendMarketingEmails,
      referral_code: localStorage.getItem(REFERRAL_CODE)
    };

    const response = await axios.post(`/users/rest/v1/user`, data);

    const { token } = response.data;

    setSession(token);
    await loadUser();

    try {
      fbq('track', 'CompleteRegistration');
      gtag('event', 'sign_up', {});
    } catch (e) {
      // ignore
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => {};

  const changePassword = async (userHash, values) => {
    const { oldPassword, newPassword } = values;

    await axios.put(
        `/users/rest/v1/users/${userHash}/password-change`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        }
    );
  };

  const updateSubscribedToNewsLetter = async (hash, values) => {
    const { marketingEmails } = values;

    const response = await axios.put(
        `/users/rest/v1/user-email-consents/${hash}`,
        {
          marketing_emails: marketingEmails,
        }
    );

    const { marketing_emails } = response;

    setUser({...userInState, email_consents: { marketing_emails }});
    dispatch({
      type: 'UPDATE',
      payload: {
        user: {
          ...userInState,
          email_consents: { marketing_emails },
        }
      }
    });
  };

  const updateProfile = async (values) => {
    const {
      name,
      surname,
      email,
      phoneNumber,
      country,
      address,
      city,
      zipCode,
      region,
      companyName,
      companyCode,
      company,
      vatCode,
    } = values;

    const updatedUser = await axios.put(
        `/users/rest/v1/users/${userManager.getUser().token}`,
        {
          name,
          surname,
          email,
          company,
          billing_address: {
            name,
            surname,
            phone_number: phoneNumber,
            street: address,
            city,
            country_code: 'LT',
            region: region,
            post_code: zipCode,
            company_name: companyName === '' || !company ? null : companyName,
            company_code: companyCode === '' || !company ? null : companyCode,
            vat_code: vatCode === '' || !company ? null : vatCode,
          },
        }
    );

    dispatch({
      type: 'UPDATE',
      payload: {
        user: {
          ...updatedUser.data,
          photoURL: "https://atvyko.s3.eu-central-1.amazonaws.com/person.png",
        }
      }
    });
  };

  const refreshUserLoyalty = async () => {
    let user = userInState;

    try {
      const loyaltyPointsResponse = await axios.get(
          `/loyalty/rest/v1/points/user/${userManager.getUser().token}`
      );

      user = {...user, loyalty_points: loyaltyPointsResponse.data};
    } catch (error) {
      user = {...user, loyalty_points: { points: 0, hash: null }};
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        user
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        updateSubscribedToNewsLetter,
        changePassword,
        loginWithToken,
        refreshUserLoyalty,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
